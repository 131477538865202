.sharc-info-container {
    font-size: 25px;
}

.modal-inside-paper {
    padding: 30px;
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}

.modal-text {
    font-size: 20px;
}

.button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

/* .reboot-buttons {
    width: 50%;
    height: 50px;
} */

.modal-inside-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.units-text {
    margin-top: 10px;
}