#metric-paper {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* align-items: flex-start; */
    padding: 10px;
}

.metric-container {
    display: flex;
    justify-content: flex-end;
    /* flex-direction: column; */
    align-items: center;
    gap: 5px;
    padding: 10px;
}

.metric-button {
    height: 45px;
    /* width: 10%; */
    text-align: center;
}

.modal-inside-paper-metric {
    padding: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.select-text {
    font-size: 25px;
    width: 50%;
}

.modal-title {
    font-size: 35px;
}

.modal-form {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
}

.metric-item {
    border: 1px solid white;
    /* width: 100%; */

}

.metric-item-container {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 20vh;
    padding: 10px;
}

.metric-info-container {
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    font-size: 20px;
    /* flex-shrink: 1; */
    text-wrap: nowrap;
}

.metric-chart-container {
    width: 95%;
    height: 100%;
}

.metric-text-container {
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}


.metric-list-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
}

.metric-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.metric-title-container {
    font-size: 40px;
}