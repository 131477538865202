.sharc-list-mui {
    overflow-y: auto;
    /* width: 40vw; */
}

#avail-dot {
    height: 10px;
    width: 10px;
    margin-right: 5px;
}

.sensor-data-in-list {
    /* width: 2%; */
    width: 100px;
    text-wrap: wrap;
}