#footer {
    /* padding: 40px; */
    height: 12vh;
    margin-top: 10px;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    font-size: 45px;
    /* border: 1px solid black; */
}

.link-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    margin-left: 20px;
    margin-top: 1vh;
}

.link-image {
    width: 40px;
    /* height: 50px; */
}

.link-inside-container {
    width: 40px;
    /* height: 50px; */
}