#login-page {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 75vh;
    align-items: center;
}

.login-fields-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login-text {
    font-size: 35px;
}

.login-inner-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;
}

.login-failed {
    color: rgb(255, 73, 73);
}