#setup-page {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 75vh;
    align-items: center;
    width: 100%;
}

.setup-container-title {
    font-size: 35px;
    width: fit-content;
}

.setup-instructions-link {
    font-size: 35px;
    text-decoration: none !important;
}

.setup-container-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.setup-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.setup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.setup-help-text {
    font-size: 25px;
}