.chart-error {
    display: flex;
    font-size: 45px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#live-chart {
    /* border: 1px solid black; */
    width: 100%;

}