#account-creation {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 75vh;
    align-items: center;
}

.account-creation-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.button-container-create {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.account-creation-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;
}