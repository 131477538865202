.App {
  text-align: center;
}

.sharc-information {
  margin: 10px 0px;
}

.sharc-container,
.loading-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 75vh;
  overflow-x: auto;
}

.loading-container {
  justify-content: center;
  align-items: center;
  gap: 0;
}


.sharc-info {
  padding: 15px;
  overflow-y: auto;
  /* width: 50%; */
}

.list-item-serial {
  width: 150px;
}

.list-item-sensor {
  width: 25px;
}

.list-item-button-internal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.sensor-image {
  height: 25px;
  width: 25px;
}

.sensor-select {}